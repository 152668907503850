import React from 'react';
import { FaLinkedin } from 'react-icons/fa';
import { TbBrandX } from 'react-icons/tb';

const Logo = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 155 154"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="mr-3 inline-block"
    >
      <ellipse cx="129.747" cy="77.1413" rx="11.1413" ry="11.1413" fill="black" stroke="black" strokeWidth="2" />
      <path d="M118.7 78.9124C120.335 84.7342 120.807 90.8209 120.089 96.8251C119.372 102.829 117.479 108.633 114.518 113.906L114.226 115.072C120.81 111.083 126.543 105.837 131.099 99.6331C135.655 93.4288 138.944 86.388 140.779 78.9125" stroke="black" strokeWidth="2" />
      <circle cx="40.2007" cy="39.9054" r="11.1413" transform="rotate(-135 40.2007 39.9054)" fill="black" stroke="black" strokeWidth="2" />
      <path d="M49.264 46.4638C52.2246 41.1912 56.1948 36.5534 60.9478 32.8152C65.7008 29.0769 71.1436 26.3115 76.9653 24.6767L77.996 24.0586C70.5206 22.2237 62.7569 21.8792 55.1483 23.0447C47.5397 24.2102 40.2352 26.8629 33.6518 30.8514" stroke="black" strokeWidth="2" />
      <circle cx="114.171" cy="40.9463" r="11.1413" transform="rotate(-45 114.171 40.9463)" fill="black" stroke="black" strokeWidth="2" />
      <path d="M107.612 50.0096C112.885 52.9702 117.523 56.9404 121.261 61.6934C124.999 66.4464 127.765 71.8892 129.399 77.7109L130.018 78.7417C131.852 71.2662 132.197 63.5025 131.031 55.8939C129.866 48.2853 127.213 40.9808 123.225 34.3974" stroke="black" strokeWidth="2" />
      <ellipse cx="25.6199" cy="76.5125" rx="11.1413" ry="11.1413" transform="rotate(180 25.6199 76.5125)" fill="black" stroke="black" strokeWidth="2" />
      <path d="M36.6661 74.7414C35.0314 68.9196 34.5593 62.8329 35.2768 56.8287C35.9944 50.8245 37.8875 45.0204 40.8481 39.7478L41.1399 38.5819C34.5565 42.5704 28.8232 47.8165 24.2672 54.0207C19.7112 60.225 16.4219 67.2658 14.587 74.7413" stroke="black" strokeWidth="2" />
      <ellipse cx="77.7531" cy="25.324" rx="11.1413" ry="11.1413" transform="rotate(-90 77.7531 25.324)" fill="black" stroke="black" strokeWidth="2" />
      <path d="M79.5247 36.3712C85.3465 34.7364 91.4332 34.2643 97.4374 34.9819C103.442 35.6994 109.246 37.5926 114.518 40.5532L115.684 40.845C111.696 34.2616 106.45 28.5282 100.245 23.9723C94.0411 19.4163 87.0003 16.127 79.5248 14.2921" stroke="black" strokeWidth="2" />
      <circle cx="40.3253" cy="113.31" r="11.1413" transform="rotate(135 40.3253 113.31)" fill="black" stroke="black" strokeWidth="2" />
      <path d="M46.8837 104.246C41.6111 101.286 36.9733 97.3155 33.2351 92.5625C29.4969 87.8095 26.7314 82.3667 25.0966 76.5449L24.4786 75.5142C22.6437 82.9897 22.2991 90.7533 23.4646 98.3619C24.6301 105.971 27.2828 113.275 31.2713 119.858" stroke="black" strokeWidth="2" />
      <ellipse cx="78.2552" cy="129.173" rx="11.1413" ry="11.1413" transform="rotate(90 78.2552 129.173)" fill="black" stroke="black" strokeWidth="2" />
      <path d="M76.4841 118.126C70.6623 119.761 64.5755 120.233 58.5713 119.515C52.5671 118.798 46.7631 116.904 41.4905 113.944L40.3246 113.652C44.3131 120.235 49.5592 125.969 55.7634 130.525C61.9677 135.081 69.0085 138.37 76.484 140.205" stroke="black" strokeWidth="2" />
      <circle cx="114.662" cy="114.095" r="11.1413" transform="rotate(45 114.662 114.095)" fill="black" stroke="black" strokeWidth="2" />
      <path d="M105.598 107.536C102.638 112.809 98.6675 117.447 93.9145 121.185C89.1615 124.923 83.7187 127.689 77.897 129.323L76.8663 129.941C84.3417 131.776 92.1054 132.121 99.714 130.955C107.323 129.79 114.627 127.137 121.211 123.149" stroke="black" strokeWidth="2" />
    </svg>
  );
};

const LandingPage = () => {
  return (
    <main className="min-h-screen flex justify-center items-start bg-[#F9F9F9] font-[Overused-Grotesk]">
      <div className="w-full max-w-xl mx-auto px-4 md:px-0 py-12 md:py-24">
        <header className="text-center">
          <h1 className="text-3xl md:text-5xl mb-6 flex items-center justify-center">
            <Logo /> Perceptron
          </h1>
        </header>

        <div className="space-y-4">
          <p className="
            text-15px leading-[1.3675rem]
            md:text-16px md:leading-[1.4588rem]
            lg:text-17px lg:leading-[1.55rem]
          ">
            The AI revolution has transformed how we reason and solve complex problems.
            Foundation models have created a new paradigm for human-computer
            interaction—but only in the digital realm. The physical world—with its dynamic
            environments, multi-modal streams, and real-time constraints—remains beyond reach.
          </p>

          <p className="
            text-15px leading-[1.3675rem]
            md:text-16px md:leading-[1.4588rem]
            lg:text-17px lg:leading-[1.55rem]
          ">
            <span className="text-[#FFA485] font-semibold">
              Current approaches to physical-world AI are fundamentally limited.
            </span>{' '}
            Each application exists in isolation: a security system can't adapt its
            understanding to manufacturing, and a robotics platform can't transfer its
            capabilities to entertainment. This fragmentation forces an ecosystem of custom
            solutions, making physical AI brittle, expensive, and difficult to scale.
          </p>

          <p className="
            text-15px leading-[1.3675rem]
            md:text-16px md:leading-[1.4588rem]
            lg:text-17px lg:leading-[1.55rem]
          ">
            Perceptron is developing the first family of{' '}
            <span className="text-[#FFA485] font-semibold">
              foundation models for the physical world
            </span>
            . Our foundation models treat all input streams—video, audio, text, and structured data—as equally important, maintaining consistent real-time performance across diverse hardware configurations.
          </p>

          <p className="
            text-15px leading-[1.3675rem]
            md:text-16px md:leading-[1.4588rem]
            lg:text-17px lg:leading-[1.55rem]
          ">
            Our mission is to deliver the{' '}
            <span className="text-[#FFA485] font-semibold">
              layer of intelligence that bridges the physical and digital worlds
            </span>
            .
          </p>

          <p className="
            text-15px leading-[1.3675rem]
            md:text-16px md:leading-[1.4588rem]
            lg:text-17px lg:leading-[1.55rem]
          ">
            We are excited to partner with Bessemer Venture Partners, S32, and Foundation
            Capital on this mission, along with Ari Morcos, Ash Arora, Ashot Arzumanyan, Azeem
            Azhar, Douwe Kiela, Jeff Blackburn, Jordan Tigani, Krishna Sridhar, Luke Zettlemoyer, Rish Gupta,
            Tanuj Thapliyal, and others!
          </p>

          <p className="
            mt-8
            text-15px leading-[1.3675rem]
            md:text-16px md:leading-[1.4588rem]
            lg:text-17px lg:leading-[1.55rem]
          ">
            We're a fully in-person team located in Seattle. If you're interested in joining us,
            get in touch:{' '}
            <i>
              <a
                href="mailto:hiring@perceptron.inc"
                className="text-[#FFA485] hover:underline font-medium"
              >
                hiring@perceptron.inc
              </a>
            </i>
          </p>

          <p className="
            mt-4 text-center
            text-15px leading-[1.3675rem]
            md:text-16px md:leading-[1.4588rem]
            lg:text-17px lg:leading-[1.55rem]
          ">
            Follow us{' '}
            <a
              href="https://x.com/perceptroninc"
              className="text-[#FFA485] hover:underline font-medium"
            >
              @perceptroninc
            </a>
          </p>

          {/* Existing section with names and social media links */}
          <div className="mt-6">
            <div className="flex flex-col md:flex-row md:justify-center md:space-x-12">
              <div className="text-center">
                <p className="font-semibold">Armen Aghajanyan</p>
                <div className="flex justify-center space-x-4 mt-2">
                  <a
                    href="https://x.com/ArmenAgha"
                    className="hover:underline inline-flex items-center text-gray-600"
                    aria-label="Armen on X"
                  >
                    <TbBrandX className="text-xl" />
                  </a>
                  <a
                    href="https://www.linkedin.com/in/armenag/"
                    className="hover:underline inline-flex items-center text-gray-600"
                    aria-label="Armen on LinkedIn"
                  >
                    <FaLinkedin className="text-xl" />
                  </a>
                </div>
              </div>
              <div className="mt-6 md:mt-0 text-center">
                <p className="font-semibold">Akshat Shrivastava</p>
                <div className="flex justify-center space-x-4 mt-2">
                  <a
                    href="https://x.com/AkshatS07"
                    className="hover:underline inline-flex items-center text-gray-600"
                    aria-label="Akshat on X"
                  >
                    <TbBrandX className="text-xl" />
                  </a>
                  <a
                    href="https://www.linkedin.com/in/akshatsh/"
                    className="hover:underline inline-flex items-center text-gray-600"
                    aria-label="Akshat on LinkedIn"
                  >
                    <FaLinkedin className="text-xl" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* End of existing section */}
        </div>
      </div>
    </main>
  );
};

export default LandingPage;
